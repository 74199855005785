/// Set position top, right, bottom, left, position, width, height and z-index values
/// @param {Number} $top [auto] Top property
/// @param {Number} $right [auto] Right property
/// @param {Number} $bottom [auto] Bottom property
/// @param {Number} $left [auto] Left property
/// @param {String} $position [static] Position value
/// @param {String} $width [static] Width value
/// @param {String} $height [static] Height value
/// @param {String} $z-index [static] Z-index value
/// @group helper

@mixin position(
  $top: auto,
  $right: auto,
  $bottom: auto,
  $left: auto,
  $position: absolute,
  $width: null,
  $height: null,
  $z-index: null
) {
  position: $position;
  z-index: $z-index;
  top: $top;
  right: $right;
  bottom: $bottom;
  left: $left;
  width: $width;
  height: $height;
  @content;
}
