@use "sass:map";

/// The keyframes cache
/// @group animation

$_keyframes: ();

/// Add CSS animation only once
/// @param {String} $name The animation name
/// @group animation

@mixin keyframes($name) {
  @if not map.has-key($_keyframes, $name) {
    @keyframes #{$name} {
      @content;
    }
    $_keyframes: map.merge($_keyframes, (#{$name}: true)) !global;
  }
}
