// blob/contact-form

@if $mode == theme or $mode == print or $mode == editor {

	.wp-block-blob-contact-form {

		> * + *,
		.form > * + * {
			margin-top: $spacing;
		}

		&__errors,
		&__confirmation {
			display: none;
		}

		&__errors {
			padding: 1em;
			background: if(get($palette, error), c(error), c(red));
			color: c(white);
		}

		&____confirmation {
			text-align: center;
			padding-top: 3em;
			padding-bottom: 3em;
		}

		&.is-submitting {

			.wp-block-blob-contact-form__form {

				> :not(.form__actions) {
					opacity: 0.5;
					pointer-events: none;
				}

				[type="submit"] {
					pointer-events: none;

					> span {
						display: block;

						@include loader(c(white), rgba(c(white), 0.3), $inner-color: transparent);
					}
				}
			}
		}

		&.has-errors {

			.wp-block-blob-contact-form__errors {
				display: block;
			}
		}

		&.is-submitted {

			.wp-block-blob-contact-form__form {
				display: none;
			}

			.wp-block-blob-contact-form__confirmation {
				display: block;
			}
		}

		.form {

			label {
				display: block;

				> span > span {
					margin-left: 0.1em;
					opacity: 0.5;
				}

				&.has-error {

					#{select(input)} {
						border-color: if(get($palette, error), c(error), c(red));
					}
				}
			}

			#{select(input)} {
				display: block;
				width: 100%;
			}

			#{select(input-clickable)} {
				margin-right: 0.5em;
			}

			.form__checkbox {
				display: flex;
				align-items: center;

				input {
					position: absolute;
					opacity: 0.001;
				}

				i {
					position: relative;
					flex-grow: 0;
					margin-right: 0.5em;
					display: block;
					width: 1em;
					height: 1em;
					flex-shrink: 0;
					background: get($styles, input background);
					border: get($styles, input border);
					border-radius: get($styles, input border-radius);
					line-height: 1;
				}

				input:checked + i {
					position: relative;
					border-color: get($styles, input focus border-color);

					@include svg-bg-icon(check, c(base), 0.9em, 0.9em);
				}
			}

			.form__actions {
				position: relative;
				text-align: right;
			}

			@include media(big) {

				> label:last-of-type {
					padding-right: 9em;
				}
			}

			.form__honeypot {

				@include hide-visually();
			}

			.wp-block-cover & {

				a {
					color: c(white);
					text-decoration: underline;

					&:hover,
					&:focus {
						text-decoration: none;
					}
				}

				#{select(input)} {
					background: transparent;
					border: 1px solid transparent;
					border-bottom-color: rgba(c(white), 0.5);

					&:focus {
						border-color: c(white);
					}

					&::placeholder {
						color: rgba(c(white), 0.75);
					}
				}

				label.has-error {

					#{select(input)} {
						border-bottom-color: if(get($palette, error), c(error), c(red));

						&:focus {
							border-color: if(get($palette, error), c(error), c(red));
						}
					}
				}

				.form__checkbox {

					i {
						border: 1px solid rgba(c(white), 0.5);
					}

					input:checked + i,
					input:focus + i {
						border: 1px solid c(white);
					}
				}
			}
		}
	}
}

@if $mode == editor {

	.edit-post-visual-editor .wp-block-blob-contact-form {

		#{select(input)} {
			border-radius: get($styles, input border-radius, 0);

			@include declare-map(remove(get($styles, input), border-radius));
		}

		#{select(button)} {
			border-radius: get($styles, button border-radius, 0);

			@include declare-map(remove(get($styles, button), border-radius));

			&:disabled {
				border: none !important;
				background: none !important;
			}
		}
	}

	[data-type="blob/contact-form"] .blob-columns {
		display: flex;
		flex-wrap: wrap;

		@include media(unbroken) {
			flex-wrap: nowrap;
		}
	}

	[data-type="blob/contact-form"] .blob-columns > * {

		@include media(unstacked) {
			flex-basis: calc(50% - 16px);
			flex-grow: 0;
			max-width: none;

			&:nth-child(2n) {
				margin-left: $gap;
			}
		}

		@include media(unbroken) {
			flex-basis: 100%;

			&:not(:first-child) {
				margin-left: $gap;
			}
		}

		@include media(unstacked broken) {

			&:nth-child(n+3) {
				margin-top: $spacing;
			}
		}

		@include media(max 599px) {
			flex-basis: 100% !important;

			&:not(:first-child) {
				margin-top: $spacing;
			}
		}
	}
}
