@use "../animation/keyframes" as *;
@use "pseudo-with-position" as *;

/// Create a dynamic, animated CSS circle loader spinner
/// @param {Color} $color [rgba(#000, 0.5)] The spinner circle segment color
/// @param {Color} $bg [rgba(#000, 0.2)] The spinner circle background
/// @param {Number} $size [1.5rem] The size of the circle
/// @param {Number} $width [2px] The width of the circle line
/// @param {Number} $animation-speed [1s] Animation speed
/// @param {Number} $inner-opacity [0.5] The opacity of the elements in the container
/// @param {Color} $inner-color [null] Set the container font color
/// @group helper

@mixin loader(
  $color: rgba(#000, 0.5),
  $bg: rgba(#000, 0.2),
  $size: 1.5rem,
  $width: 2px,
  $animation-speed: 1s,
  $inner-opacity: 0.5,
  $inner-color: null
) {
  position: relative;
  color: $inner-color;

  > * {
    opacity: $inner-opacity;
  }

  @include pseudo-with-position(50%, auto, auto, 50%, absolute, $size, $size, null, "", block, "&::before, &::after") {
    border-width: $width;
    border-radius: 100%;
    border-style: solid;
    transform: translate3d(-50%, -50%, 0);

    @content;
  }

  &::before {
    z-index: 1;
    border-color: $bg;
  }

  &::after {
    z-index: 2;
    border-color: $color transparent transparent transparent;
    animation: loader-spin $animation-speed linear infinite;

    @include keyframes(loader-spin) {
      0% {
        transform: translate3d(-50%, -50%, 0) rotate(0);
      }

      100% {
        transform: translate3d(-50%, -50%, 0) rotate(360deg);
      }
    }
  }
}