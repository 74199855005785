.wp-block-blob-contact-form > * + *,
.wp-block-blob-contact-form .form > * + * {
  margin-top: 1.5rem;
}
.wp-block-blob-contact-form__errors, .wp-block-blob-contact-form__confirmation {
  display: none;
}
.wp-block-blob-contact-form__errors {
  padding: 1em;
  background: red;
  color: #fff;
}
.wp-block-blob-contact-form____confirmation {
  text-align: center;
  padding-top: 3em;
  padding-bottom: 3em;
}
.wp-block-blob-contact-form.is-submitting .wp-block-blob-contact-form__form > :not(.form__actions) {
  opacity: 0.5;
  pointer-events: none;
}
.wp-block-blob-contact-form.is-submitting .wp-block-blob-contact-form__form [type=submit] {
  pointer-events: none;
}
.wp-block-blob-contact-form.is-submitting .wp-block-blob-contact-form__form [type=submit] > span {
  display: block;
  position: relative;
  color: transparent;
}
.wp-block-blob-contact-form.is-submitting .wp-block-blob-contact-form__form [type=submit] > span > * {
  opacity: 0.5;
}
.wp-block-blob-contact-form.is-submitting .wp-block-blob-contact-form__form [type=submit] > span::before, .wp-block-blob-contact-form.is-submitting .wp-block-blob-contact-form__form [type=submit] > span::after {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  right: auto;
  bottom: auto;
  left: 50%;
  width: 1.5rem;
  height: 1.5rem;
  border-width: 2px;
  border-radius: 100%;
  border-style: solid;
  transform: translate3d(-50%, -50%, 0);
}
.wp-block-blob-contact-form.is-submitting .wp-block-blob-contact-form__form [type=submit] > span::before {
  z-index: 1;
  border-color: rgba(255, 255, 255, 0.3);
}
.wp-block-blob-contact-form.is-submitting .wp-block-blob-contact-form__form [type=submit] > span::after {
  z-index: 2;
  border-color: #fff transparent transparent transparent;
  animation: loader-spin 1s linear infinite;
}
@keyframes loader-spin {
  0% {
    transform: translate3d(-50%, -50%, 0) rotate(0);
  }
  100% {
    transform: translate3d(-50%, -50%, 0) rotate(360deg);
  }
}
.wp-block-blob-contact-form.has-errors .wp-block-blob-contact-form__errors {
  display: block;
}
.wp-block-blob-contact-form.is-submitted .wp-block-blob-contact-form__form {
  display: none;
}
.wp-block-blob-contact-form.is-submitted .wp-block-blob-contact-form__confirmation {
  display: block;
}
.wp-block-blob-contact-form .form label {
  display: block;
}
.wp-block-blob-contact-form .form label > span > span {
  margin-left: 0.1em;
  opacity: 0.5;
}
.wp-block-blob-contact-form .form label.has-error input:not([type]), .wp-block-blob-contact-form .form label.has-error [type=color], .wp-block-blob-contact-form .form label.has-error [type=date], .wp-block-blob-contact-form .form label.has-error [type=datetime], .wp-block-blob-contact-form .form label.has-error [type=datetime-local], .wp-block-blob-contact-form .form label.has-error [type=email], .wp-block-blob-contact-form .form label.has-error [type=image], .wp-block-blob-contact-form .form label.has-error [type=month], .wp-block-blob-contact-form .form label.has-error [type=number], .wp-block-blob-contact-form .form label.has-error [type=password], .wp-block-blob-contact-form .form label.has-error [type=search], .wp-block-blob-contact-form .form label.has-error [type=tel], .wp-block-blob-contact-form .form label.has-error [type=text], .wp-block-blob-contact-form .form label.has-error [type=time], .wp-block-blob-contact-form .form label.has-error [type=url], .wp-block-blob-contact-form .form label.has-error [type=week], .wp-block-blob-contact-form .form label.has-error select, .wp-block-blob-contact-form .form label.has-error textarea {
  border-color: red;
}
.wp-block-blob-contact-form .form input:not([type]), .wp-block-blob-contact-form .form [type=color], .wp-block-blob-contact-form .form [type=date], .wp-block-blob-contact-form .form [type=datetime], .wp-block-blob-contact-form .form [type=datetime-local], .wp-block-blob-contact-form .form [type=email], .wp-block-blob-contact-form .form [type=image], .wp-block-blob-contact-form .form [type=month], .wp-block-blob-contact-form .form [type=number], .wp-block-blob-contact-form .form [type=password], .wp-block-blob-contact-form .form [type=search], .wp-block-blob-contact-form .form [type=tel], .wp-block-blob-contact-form .form [type=text], .wp-block-blob-contact-form .form [type=time], .wp-block-blob-contact-form .form [type=url], .wp-block-blob-contact-form .form [type=week], .wp-block-blob-contact-form .form select, .wp-block-blob-contact-form .form textarea {
  display: block;
  width: 100%;
}
.wp-block-blob-contact-form .form [type=checkbox], .wp-block-blob-contact-form .form [type=radio] {
  margin-right: 0.5em;
}
.wp-block-blob-contact-form .form .form__checkbox {
  display: flex;
  align-items: center;
}
.wp-block-blob-contact-form .form .form__checkbox input {
  position: absolute;
  opacity: 0.001;
}
.wp-block-blob-contact-form .form .form__checkbox i {
  position: relative;
  flex-grow: 0;
  margin-right: 0.5em;
  display: block;
  width: 1em;
  height: 1em;
  flex-shrink: 0;
  background: #fff;
  border: 1px solid #1fb5ca;
  line-height: 1;
}
.wp-block-blob-contact-form .form .form__checkbox input:checked + i {
  position: relative;
  border-color: #333;
}
.wp-block-blob-contact-form .form .form__checkbox input:checked + i::before {
  content: "";
  display: inline-flex;
  width: 0.9em;
  height: 0.9em;
  background-image: url("data:image/svg+xml,%3Csvg fill='%23333' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 48 48'%3E%3Cpath d='M18 32.34L9.66 24l-2.83 2.83L18 38l24-24-2.83-2.83z' /%3E%3C/svg%3E");
  background-size: contain;
  background-repeat: no-repeat;
}
.wp-block-blob-contact-form .form .form__actions {
  position: relative;
  text-align: right;
}
@media only screen and (min-width: 1100px) {
  .wp-block-blob-contact-form .form > label:last-of-type {
    padding-right: 9em;
  }
}
.wp-block-blob-contact-form .form .form__honeypot {
  position: absolute;
  left: -999999px;
  overflow: hidden;
  visibility: hidden;
  opacity: 0.00001;
}
.wp-block-cover .wp-block-blob-contact-form .form a {
  color: #fff;
  text-decoration: underline;
}
.wp-block-cover .wp-block-blob-contact-form .form a:hover, .wp-block-cover .wp-block-blob-contact-form .form a:focus {
  text-decoration: none;
}
.wp-block-cover .wp-block-blob-contact-form .form input:not([type]), .wp-block-cover .wp-block-blob-contact-form .form [type=color], .wp-block-cover .wp-block-blob-contact-form .form [type=date], .wp-block-cover .wp-block-blob-contact-form .form [type=datetime], .wp-block-cover .wp-block-blob-contact-form .form [type=datetime-local], .wp-block-cover .wp-block-blob-contact-form .form [type=email], .wp-block-cover .wp-block-blob-contact-form .form [type=image], .wp-block-cover .wp-block-blob-contact-form .form [type=month], .wp-block-cover .wp-block-blob-contact-form .form [type=number], .wp-block-cover .wp-block-blob-contact-form .form [type=password], .wp-block-cover .wp-block-blob-contact-form .form [type=search], .wp-block-cover .wp-block-blob-contact-form .form [type=tel], .wp-block-cover .wp-block-blob-contact-form .form [type=text], .wp-block-cover .wp-block-blob-contact-form .form [type=time], .wp-block-cover .wp-block-blob-contact-form .form [type=url], .wp-block-cover .wp-block-blob-contact-form .form [type=week], .wp-block-cover .wp-block-blob-contact-form .form select, .wp-block-cover .wp-block-blob-contact-form .form textarea {
  background: transparent;
  border: 1px solid transparent;
  border-bottom-color: rgba(255, 255, 255, 0.5);
}
.wp-block-cover .wp-block-blob-contact-form .form input:not([type]):focus, .wp-block-cover .wp-block-blob-contact-form .form [type=color]:focus, .wp-block-cover .wp-block-blob-contact-form .form [type=date]:focus, .wp-block-cover .wp-block-blob-contact-form .form [type=datetime]:focus, .wp-block-cover .wp-block-blob-contact-form .form [type=datetime-local]:focus, .wp-block-cover .wp-block-blob-contact-form .form [type=email]:focus, .wp-block-cover .wp-block-blob-contact-form .form [type=image]:focus, .wp-block-cover .wp-block-blob-contact-form .form [type=month]:focus, .wp-block-cover .wp-block-blob-contact-form .form [type=number]:focus, .wp-block-cover .wp-block-blob-contact-form .form [type=password]:focus, .wp-block-cover .wp-block-blob-contact-form .form [type=search]:focus, .wp-block-cover .wp-block-blob-contact-form .form [type=tel]:focus, .wp-block-cover .wp-block-blob-contact-form .form [type=text]:focus, .wp-block-cover .wp-block-blob-contact-form .form [type=time]:focus, .wp-block-cover .wp-block-blob-contact-form .form [type=url]:focus, .wp-block-cover .wp-block-blob-contact-form .form [type=week]:focus, .wp-block-cover .wp-block-blob-contact-form .form select:focus, .wp-block-cover .wp-block-blob-contact-form .form textarea:focus {
  border-color: #fff;
}
.wp-block-cover .wp-block-blob-contact-form .form input:not([type])::placeholder, .wp-block-cover .wp-block-blob-contact-form .form [type=color]::placeholder, .wp-block-cover .wp-block-blob-contact-form .form [type=date]::placeholder, .wp-block-cover .wp-block-blob-contact-form .form [type=datetime]::placeholder, .wp-block-cover .wp-block-blob-contact-form .form [type=datetime-local]::placeholder, .wp-block-cover .wp-block-blob-contact-form .form [type=email]::placeholder, .wp-block-cover .wp-block-blob-contact-form .form [type=image]::placeholder, .wp-block-cover .wp-block-blob-contact-form .form [type=month]::placeholder, .wp-block-cover .wp-block-blob-contact-form .form [type=number]::placeholder, .wp-block-cover .wp-block-blob-contact-form .form [type=password]::placeholder, .wp-block-cover .wp-block-blob-contact-form .form [type=search]::placeholder, .wp-block-cover .wp-block-blob-contact-form .form [type=tel]::placeholder, .wp-block-cover .wp-block-blob-contact-form .form [type=text]::placeholder, .wp-block-cover .wp-block-blob-contact-form .form [type=time]::placeholder, .wp-block-cover .wp-block-blob-contact-form .form [type=url]::placeholder, .wp-block-cover .wp-block-blob-contact-form .form [type=week]::placeholder, .wp-block-cover .wp-block-blob-contact-form .form select::placeholder, .wp-block-cover .wp-block-blob-contact-form .form textarea::placeholder {
  color: rgba(255, 255, 255, 0.75);
}
.wp-block-cover .wp-block-blob-contact-form .form label.has-error input:not([type]), .wp-block-cover .wp-block-blob-contact-form .form label.has-error [type=color], .wp-block-cover .wp-block-blob-contact-form .form label.has-error [type=date], .wp-block-cover .wp-block-blob-contact-form .form label.has-error [type=datetime], .wp-block-cover .wp-block-blob-contact-form .form label.has-error [type=datetime-local], .wp-block-cover .wp-block-blob-contact-form .form label.has-error [type=email], .wp-block-cover .wp-block-blob-contact-form .form label.has-error [type=image], .wp-block-cover .wp-block-blob-contact-form .form label.has-error [type=month], .wp-block-cover .wp-block-blob-contact-form .form label.has-error [type=number], .wp-block-cover .wp-block-blob-contact-form .form label.has-error [type=password], .wp-block-cover .wp-block-blob-contact-form .form label.has-error [type=search], .wp-block-cover .wp-block-blob-contact-form .form label.has-error [type=tel], .wp-block-cover .wp-block-blob-contact-form .form label.has-error [type=text], .wp-block-cover .wp-block-blob-contact-form .form label.has-error [type=time], .wp-block-cover .wp-block-blob-contact-form .form label.has-error [type=url], .wp-block-cover .wp-block-blob-contact-form .form label.has-error [type=week], .wp-block-cover .wp-block-blob-contact-form .form label.has-error select, .wp-block-cover .wp-block-blob-contact-form .form label.has-error textarea {
  border-bottom-color: red;
}
.wp-block-cover .wp-block-blob-contact-form .form label.has-error input:not([type]):focus, .wp-block-cover .wp-block-blob-contact-form .form label.has-error [type=color]:focus, .wp-block-cover .wp-block-blob-contact-form .form label.has-error [type=date]:focus, .wp-block-cover .wp-block-blob-contact-form .form label.has-error [type=datetime]:focus, .wp-block-cover .wp-block-blob-contact-form .form label.has-error [type=datetime-local]:focus, .wp-block-cover .wp-block-blob-contact-form .form label.has-error [type=email]:focus, .wp-block-cover .wp-block-blob-contact-form .form label.has-error [type=image]:focus, .wp-block-cover .wp-block-blob-contact-form .form label.has-error [type=month]:focus, .wp-block-cover .wp-block-blob-contact-form .form label.has-error [type=number]:focus, .wp-block-cover .wp-block-blob-contact-form .form label.has-error [type=password]:focus, .wp-block-cover .wp-block-blob-contact-form .form label.has-error [type=search]:focus, .wp-block-cover .wp-block-blob-contact-form .form label.has-error [type=tel]:focus, .wp-block-cover .wp-block-blob-contact-form .form label.has-error [type=text]:focus, .wp-block-cover .wp-block-blob-contact-form .form label.has-error [type=time]:focus, .wp-block-cover .wp-block-blob-contact-form .form label.has-error [type=url]:focus, .wp-block-cover .wp-block-blob-contact-form .form label.has-error [type=week]:focus, .wp-block-cover .wp-block-blob-contact-form .form label.has-error select:focus, .wp-block-cover .wp-block-blob-contact-form .form label.has-error textarea:focus {
  border-color: red;
}
.wp-block-cover .wp-block-blob-contact-form .form .form__checkbox i {
  border: 1px solid rgba(255, 255, 255, 0.5);
}
.wp-block-cover .wp-block-blob-contact-form .form .form__checkbox input:checked + i,
.wp-block-cover .wp-block-blob-contact-form .form .form__checkbox input:focus + i {
  border: 1px solid #fff;
}