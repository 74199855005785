// configure variables for common styles

@use "../node_modules/sass-styler/styler" as *;

// spacing
$spacing-mode: owl;
$spacing: 1.5rem; // vertical
$gap: 2 * $spacing; // horizontal
$pad: $spacing; // padding

/// theme colours
$palette: merge($palette, (
	black: #000,
	white: #fff,
	grey: #eef3f5,
	base: #333,
	primary: #1fb5ca,
	secondary: tetrad(#1fb5ca),
	tertiary: #8fa5ad
));

// font size modifiers
$text-variations: (
	small: (
		font-size: 0.875em,
	),
	normal: (
		font-size: 1em
	),
	medium: (
		font-size: 1.125em
	),
	large: (
		font-size: 1.5em
	),
	huge: (
		font-size: 2em
	)
) !default;

// styles
$styles: merge($styles, (
	indent: 1.75em,
	color: c(base),
	line-height: 1.5,
	font-size: 16px,
	font-family: ("Lora", "Times New Roman", times, serif),
	font-feature-settings: "lnum",
	font-variant-numeric: lining-nums,
	heading: (
		line-height: 1.1,
		font-family: ("Work Sans", -apple-system, blinkmacsystemfont, "Helvetica Neue", helvetica, sans-serif),
		font-size: 1.1em,
		font-weight: 300,
		text-transform: uppercase
	),
	h1: ( font-size: 3em ),
	h2: ( font-size: 2em ),
	h3: ( font-size: 1.25em ),
	link: (
		color: c(primary),
		text-decoration: none,
		hover-focus: (
			color: c(primary, 30)
		)
	),
	input: (
		height: auto,
		min-height: 0,
		padding: 0.5em 1em,
		border: 1px solid c(primary),
		background: c(white),
		outline: 0,
		line-height: 1.5em,
		focus: (
			border-color: c(base)
		)
	),
	button: (
		display: inline-flex,
		padding: 0.5em 1em,
		border: 1px solid c(primary),
		background-color: c(primary),
		outline: 0,
		font-family: ("Work Sans", -apple-system, blinkmacsystemfont, "Helvetica Neue", helvetica, sans-serif),
		font-weight: 300,
		text-transform: uppercase,
		line-height: 1.5em,
		color: c(white),
		cursor: pointer,
		hover-focus: (
			background-color: c(primary, -20),
			border-color: c(primary, -20)
		),
		read-only: (
			cursor: pointer
		)
	)
));

// media queries
$media-queries: merge($media-queries, (
	// container related media queries
	wide: screen 1232px,
	narrow: screen (max 1231px),
	uncontained: screen 896px,
	contained: screen (max 895px),
	// wp default blocks stack media queries
	unbroken: screen 782px,
	broken: screen (max 781px),
	unstacked: screen 600px,
	stacked: screen (max 599px),
	// theme media
	big: screen 1100px,
	small: screen (max 1099px)
));

// containers
$container-default: wide;
$container: merge($container, (
	normal: 864px,
	wide: 1200px,
	margin: 16px,
));

// transition
$transition: (
	duration: 0.3s,
	timing-function: ease-out
);

$svg-icons: merge($svg-icons, (
	linkedin: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 504.4 504.4" id="linkedin"><path d="M377.6.2H126.4C56.8.2 0 57 0 126.6v251.6c0 69.2 56.8 126 126.4 126H378c69.6 0 126.4-56.8 126.4-126.4V126.6C504 57 447.2.2 377.6.2zM168 408.2H96v-208h72v208zm-36.4-240c-20.4 0-36.8-16.4-36.8-36.8s16.4-36.8 36.8-36.8 36.8 16.4 36.8 36.8c-.4 20.4-16.8 36.8-36.8 36.8zm276.8 240H348V307.4c0-24.4-3.2-55.6-36.4-55.6-34 0-39.6 26.4-39.6 54v102.4h-60v-208h56v28h1.6c8.8-16 29.2-28.4 61.2-28.4 66 0 77.6 38 77.6 94.4v114z"/></svg>',
	quote: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" id="quotes"><path d="M12 34h6l4-8V14H10v12h6zm16 0h6l4-8V14H26v12h6z"></path></svg>'
));

$asset-path: "/wp-content/themes/project/";
