/// Visually hide an element
/// @param {String} $position [absolute] Position value
/// @param {String} $overflow [hidden] Overflow value
/// @param {String} $visibility [hidden] Visibility value
/// @param {Number} $opacity [0.00001] Opacity value
/// @param {Number} $left [-999999px] Left property
/// @group helper

@mixin hide-visually(
  $position: absolute,
  $overflow: hidden,
  $visibility: hidden,
  $opacity: 0.00001,
  $left: -999999px
) {
  position: $position;
  left: $left;
  overflow: $overflow;
  visibility: $visibility;
  opacity: $opacity;
}
