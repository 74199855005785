/// SVG icons for base64 urls
/// @group helper

$svg-icons: (
    chevron-up: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48"><path d="M24 16L12 28l2.83 2.83L24 21.66l9.17 9.17L36 28z" /></svg>',
    chevron-right: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48"><path d="M20 12l-2.83 2.83L26.34 24l-9.17 9.17L20 36l12-12z" /></svg>',
    chevron-down: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48"><path d="M33.17 17.17L24 26.34l-9.17-9.17L12 20l12 12 12-12z" /></svg>',
    chevron-left: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48"><path d="M30.83 14.83L28 12 16 24l12 12 2.83-2.83L21.66 24z" /></svg>',
    arrow-up: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48"><path d="M8 24l2.83 2.83L22 15.66V40h4V15.66l11.17 11.17L40 24 24 8 8 24z" /></svg>',
    arrow-right: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48"><path d="M24 8l-2.83 2.83L32.34 22H8v4h24.34L21.17 37.17 24 40l16-16z" /></svg>',
    arrow-down: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48"><path d="M40 24l-2.82-2.82L26 32.34V8h-4v24.34L10.84 21.16 8 24l16 16 16-16z" /></svg>',
    arrow-left: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48"><path d="M40 22H15.66l11.17-11.17L24 8 8 24l16 16 2.83-2.83L15.66 26H40v-4z" /></svg>',
    close: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48"><path d="M38 12.83L35.17 10 24 21.17 12.83 10 10 12.83 21.17 24 10 35.17 12.83 38 24 26.83 35.17 38 38 35.17 26.83 24z" /></svg>',
    check: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48"><path d="M18 32.34L9.66 24l-2.83 2.83L18 38l24-24-2.83-2.83z" /></svg>',
    menu: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48"><path d="M6 36h36v-4H6v4zm0-10h36v-4H6v4zm0-14v4h36v-4H6z" /></svg>',
    minus: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48"><path d="M38 26H10v-4h28v4z" /></svg>',
    plus: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48"><path d="M38 26H26v12h-4V26H10v-4h12V10h4v12h12v4z" /></svg>',
    quotes: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48"><path d="M12 34h6l4-8V14H10v12h6zm16 0h6l4-8V14H26v12h6z" /></svg>',
    search: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48"><path d="M31 28h-1.59l-.55-.55C30.82 25.18 32 22.23 32 19c0-7.18-5.82-13-13-13S6 11.82 6 19s5.82 13 13 13c3.23 0 6.18-1.18 8.45-3.13l.55.55V31l10 9.98L40.98 38 31 28zm-12 0a9 9 0 1 1 .001-18.001A9 9 0 0 1 19 28z" /></svg>',
    star: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z" /></svg>',
    star-empty: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M22 9.24l-7.19-.62L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21 12 17.27 18.18 21l-1.63-7.03L22 9.24zM12 15.4l-3.76 2.27 1-4.28-3.32-2.88 4.38-.38L12 6.1l1.71 4.04 4.38.38-3.32 2.88 1 4.28L12 15.4z" /></svg>',
) !default;

@use "sass:string";
@use "../utility/get" as *;
@use "./inline-svg-url" as *;
@use "./script-direction" as *;

/// Display an svg icon as a base64 encoded background
/// @param {String} $icon The icon SVG code or key from $svg-icons
/// @param {Color} $color [null] The icon color
/// @param {Number} $width [null] The width of the icon
/// @param {Number} $height [$width] The height of the icon
/// @param {Number} $gap [null] A gap between the icon and the text next to it
/// @param {String} $selector ["&::before"] The selector for the icon background

@mixin svg-bg-icon($icon, $color: null, $width: null, $height: $width, $gap: null, $selector: "&::before") {
    $svg: get($svg-icons, $icon);
    $svg: if($svg, $svg, $icon);

    #{$selector} {
        content: if(string.index($selector, "::"), "", null);
        display: if(string.index($selector, "::"), inline-flex, null);
        width: $width;
        height: $height;
        margin-#{script-direction(backward)}: $gap;
        background-image: inline-svg-url($svg, $color);
        background-size: contain;
        background-repeat: no-repeat;

        @content;
    }
}